import gql from 'graphql-tag';

const SUBMIT_FORM = gql`
  mutation submitForm($input: SubmitFormInput!) {
    submitForm(input: $input)
  }
`;

const VALID_FORM = gql`
  mutation validForm($input: [ReviewVaspInput!]!) {
    validForm(inputs: $input)
  }
`;

export { SUBMIT_FORM, VALID_FORM };
