import gql from 'graphql-tag';

const GET_REVIEW_LIST = gql`
  query GetReviewList($status: [OperationStatus!]) {
    getReviewList(filter_by_status: $status) {
      id
      operation
      vasp_code_prefix
      country_code
      city_code
      branch_code
      vasp_name
      go_live_dt
      vasp_status
      registration_type
      vasp_email
      account_expiration_dt
      vasp_server_status
    }
  }
`;

const GET_REVIEW = gql`
  query GET_REVIEW($id: ID!) {
    getReview(id: $id) {
      id
      serial_number
      user_id
      signature
      reason
      operation
      vasp_status
      go_live_dt
      vasp_name
      vasp_code_prefix
      country_code
      city_code
      branch_code
      company_id
      vasp_category
      vasp_receive_url
      vasp_fixed_ip
      pem_pubkey
      vasp_email
      callback_host_url
      callback_txid_url
      callback_validate_addr_url
      registration_type
      notification_receivers
      assets {
        currency_id
        address
        addr_extra_info
      }
      errors {
        vaspCode
        emailCheck
      }
      callback_vasp_server_health_check_url
      estimated_customer_number
      estimated_annual_transaction_volume
      estimated_annual_travel_rule_data_transfer_number
      account_expiration_dt
      vasp_server_status
      recipient_options {
        all
        customVaspRecipients
        otherEmails
      }
      recipients {
        customVasps
        otherEmails
      }
      is_gtr_active
      gtr_expired_date
      is_code_active
      code_expired_date
      paidInteroperability
    }
  }
`;

const REVIEW_VASP = gql`
  mutation updateVASP($input: [ReviewVaspInput!]!) {
    reviewVasp(inputs: $input)
  }
`;

export { GET_REVIEW_LIST, GET_REVIEW, REVIEW_VASP };
