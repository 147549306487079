import gql from 'graphql-tag';

const GET_VASP = gql`
  query VASP {
    vasps {
      id
      vasp_name
      vasp_email
      vasp_status
      token_expires_in
    }
  }
`;

const GET_VASP_COUNT = gql`
  query vaspCount {
    vaspCount
  }
`;

const GET_LATEST_VASP = gql`
  query getLatestVasp {
    getLatestVasp
  }
`;

const VASP_NET_SEARCH_VASP = gql`
  query searchVaspRegulation($vaspName: String!) {
    searchVaspRegulation(vaspName: $vaspName) {
      vaspIdentifier
      vaspName
      vaspOtherName
      supervisoryAuthority
      countryCode
      licenseRegistrationId
      regulatoryStatus
      regulatoryStatusDate
      statusStartDate
      statusExpiryDate
      regulatoryData
      active
    }
  }
`;

const CREATE_VASP = gql`
  mutation createVASP($input: CreateVaspInput!) {
    createVASP(input: $input) {
      id
    }
  }
`;

const UPDATE_VASP = gql`
  mutation updateVASP($id: ID!, $input: UpdateVaspInput!) {
    updateVASP(id: $id, input: $input) {
      serial_number
    }
  }
`;

const DELETE_VASP = gql`
  mutation deleteVASP($id: ID!, $go_live_dt: String!) {
    deleteVASP(id: $id, go_live_dt: $go_live_dt) {
      serial_number
    }
  }
`;

const GET_VASP_API_KEY = gql`
  query vaspApiKey($vasp_code: String!) {
    vaspApiKey(vasp_code: $vasp_code)
  }
`;

const RENEW_VASP_API_KEY = gql`
  mutation renewVaspApiKey($oldVaspApiKey: String!) {
    renewVaspApiKey(oldVaspApiKey: $oldVaspApiKey)
  }
`;

const VERIFY_REGISTER_TOKEN = gql`
  mutation verifyRegisterToken($token: String!) {
    verifyRegisterToken(token: $token) {
      id
      env
    }
  }
`;

const GET_VASP_REGISTER = gql`
  query getVaspRegister($filter_active: Boolean, $get_currencies: Boolean!) {
    getVaspRegister {
      id
      operation
      vasp_status
      go_live_dt
      vasp_name
      vasp_code_prefix
      country_code
      city_code
      branch_code
      company_id
      vasp_category
      vasp_receive_url
      vasp_fixed_ip
      pem_pubkey
      vasp_email
      callback_validate_addr_url
      callback_txid_url
      notification_receivers
      assets {
        currency_id
        address
        addr_extra_info
      }
    }
    getCurrencies(filter_active: $filter_active) @include(if: $get_currencies) {
      currency_id
      currency_symbol
      currency_name
      is_active
      is_token
      addr_extra_info
    }
  }
`;

const LIST_RISK_REPORT = gql`
  query listRiskReport($prefix: String) {
    listRiskReport(prefix: $prefix)
  }
`;

const GET_RISK_REPORT_LINK = gql`
  query getRiskReportLink($fileKey: String!) {
    getRiskReportLink(fileKey: $fileKey)
  }
`;

const GET_VASP_LIST = gql`
  query GetVaspList {
    getVaspList {
      vasp_code
      vasp_name
      created_at
    }
  }
`;

const GET_VASP_REQUEST_LOG = gql`
  query GetVaspRequestLog($vasp_code: String!) {
    getVaspRequestLog(vasp_code: $vasp_code) {
      path
      complete
      last_request_at
      request_count
    }
  }
`;

const GET_VASP_BUNDLE_SERVICE = gql`
  query GetVaspBundleService($vasp_code: String!) {
    getVaspBundleService(vasp_code: $vasp_code) {
      vasp_code
      service_provider
      service_type
      service_desc
      is_active
      started_at
      ended_at
      credential
      status
    }
  }
`;

const SWITCH_BUNDLE_SERVICE = gql`
  mutation SwitchBundleService($input: SwitchBundleServiceInput!) {
    switchBundleService(input: $input)
  }
`;

const GET_VASP_REGULATIONS = gql`
  query GetVaspRegulations {
    getVaspRegulations {
      vasp_code
      vasp_name
      status
      updated_at
      data {
        regulatoryStatus
      }
    }
  }
`;

const GET_VASP_REGULATION_DETAIL = gql`
  query GetVaspRegulationDetail($vasp_code: String!) {
    getVaspRegulationDetail(vasp_code: $vasp_code) {
      data {
        vasp_code
        vasp_name
        status
        created_at
        updated_at
        data {
          vaspIdentifier
          vaspName
          vaspOtherName
          supervisoryAuthority
          countryCode
          licenseRegistrationId
          regulatoryStatus
          regulatoryStatusDate
          statusStartDate
          statusExpiryDate
          lastChecked
          resultType
          nameType
          regulatoryData
          active
        }
      }
      log {
        vasp_code
        created_at
        data {
          vaspResults {
            vaspIdentifier
            vaspName
            vaspOtherName
            supervisoryAuthority
            countryCode
            licenseRegistrationId
            regulatoryStatus
            regulatoryStatusDate
            statusStartDate
            statusExpiryDate
            lastChecked
            resultType
            nameType
            regulatoryData
            active
          }
        }
      }
    }
  }
`;

const UPDATE_VASP_REGULATION = gql`
  mutation UpdateVaspRegulation($vaspCode: String!, $vaspIdentifier: String) {
    updateVaspRegulation(vaspCode: $vaspCode, vaspIdentifier: $vaspIdentifier)
  }
`;

const DELIST_VASP = gql`
  mutation DelistVasp($vaspCode: String!, $vaspIdentifier: String) {
    delistVasp(vaspCode: $vaspCode, vaspIdentifier: $vaspIdentifier)
  }
`;

const VERIFY_VASP_CODE = gql`
  mutation VerifyVaspCode($input: VerifyVaspCodeInput!) {
    verifyVaspCode(input: $input)
  }
`;
const VERIFY_VASP_EMAIL = gql`
  mutation VerifyVaspEmail($email: String!) {
    verifyVaspEmail(email: $email)
  }
`;

export {
  GET_VASP,
  GET_VASP_COUNT,
  GET_LATEST_VASP,
  VASP_NET_SEARCH_VASP,
  CREATE_VASP,
  UPDATE_VASP,
  DELETE_VASP,
  GET_VASP_API_KEY,
  RENEW_VASP_API_KEY,
  VERIFY_REGISTER_TOKEN,
  GET_VASP_REGISTER,
  LIST_RISK_REPORT,
  GET_RISK_REPORT_LINK,
  GET_VASP_LIST,
  GET_VASP_REQUEST_LOG,
  GET_VASP_BUNDLE_SERVICE,
  SWITCH_BUNDLE_SERVICE,
  GET_VASP_REGULATIONS,
  GET_VASP_REGULATION_DETAIL,
  UPDATE_VASP_REGULATION,
  DELIST_VASP,
  VERIFY_VASP_CODE,
  VERIFY_VASP_EMAIL,
};
