import gql from 'graphql-tag';

const GET_TRANSFER_QUERY = gql`
  query GetTransfer(
    $ocode: String
    $bcode: String
    $oaddrs: [String]
    $baddrs: [String]
    $interval: String
    $currency_id: String
    $status: [String]
    $transfer_ids: [String]
    $offset: Int
  ) {
    transfer(
      orig_vasp_code: $ocode
      bene_vasp_code: $bcode
      orig_addrs: $oaddrs
      bene_addrs: $baddrs
      transfer_ids: $transfer_ids
      interval: $interval
      currency_id: $currency_id
      status: $status
      offset: $offset
    ) {
      nextOffset
      result {
        transfer_id
        data_dt
        status
        transaction {
          originator_vasp {
            vasp_code
            addrs {
              address
              addr_extra_info
            }
          }
          beneficiary_vasp {
            vasp_code
            addrs {
              address
              addr_extra_info
            }
          }
          currency_id
        }
      }
    }
  }
`;
const GET_TRANSFER_BY_ID_QUERY = gql`
  query GetTransferById($id: String) {
    transferById(transfer_id: $id) {
      transfer_id
      callback_url
      data_dt
      status
      created_at
      updated_at
      transfer_to_originator_time
      transfer_to_beneficiary_time
      permission_status
      transfer_to_originator_res_message
      transfer_to_beneficiary_res_message
      transaction {
        originator_vasp {
          vasp_code
          addrs {
            address
            addr_extra_info
          }
        }
        beneficiary_vasp {
          vasp_code
          addrs {
            address
            addr_extra_info
          }
        }
        currency_id
      }
      expire_date
      transfer_to_originator_validate_addr_res_message
      validate_addr_error_message
    }
  }
`;

const GET_TRANSFER_STATISTICS = gql`
  query getTransferStatistics($country_code: [String]) {
    getTransferStatistics(country_code: $country_code) {
      countryStatistics {
        country
        week
        oldWeek
        month
        oldMonth
        allTime
      }
      totalCount {
        week
        oldWeek
        month
        oldMonth
        total
      }
    }
  }
`;

const GET_CURRENCIES = gql`
  query GetCurrencies($filter_active: Boolean) {
    getCurrencies(filter_active: $filter_active) {
      currency_id
      currency_symbol
      currency_name
      is_active
      is_token
      addr_extra_info
    }
  }
`;

export { GET_TRANSFER_QUERY, GET_TRANSFER_BY_ID_QUERY, GET_TRANSFER_STATISTICS, GET_CURRENCIES };
