import gql from 'graphql-tag';

const CREATE_UPDATE_REQUEST = gql`
  mutation createUpdateRequest($input: CreateUpdateRequestInput!) {
    createUpdateRequest(input: $input)
  }
`;

const REVIEW_UPDATE_REQUEST = gql`
  mutation reviewUpdateRequest($id: ID!, $approve: Boolean!) {
    reviewUpdateRequest(id: $id, approve: $approve)
  }
`;

const GET_UPDATE_REQUEST_LIST = gql`
  query getVaspUpdateRequestList {
    getVaspUpdateRequestList {
      id
      operation
      vasp_code
      vasp_name
      go_live_dt
      vasp_status
      registration_type
      vasp_email
      account_expiration_dt
    }
  }
`;

const GET_UPDATE_REQUEST = gql`
  query getVaspUpdateRequest($id: ID!) {
    getVaspUpdateRequest(id: $id) {
      id
      operation
      vasp_status
      go_live_dt
      vasp_name
      company_id
      vasp_category
      vasp_fixed_ip
      pem_pubkey
      vasp_email
      vasp_receive_url
      callback_txid_url
      callback_validate_addr_url
      registration_type
      callback_vasp_server_health_check_url
      estimated_customer_number
      estimated_annual_transaction_volume
      estimated_annual_travel_rule_data_transfer_number
      account_expiration_dt
      vasp_code
      notification_receivers
      assets {
        currency_id
        address
        addr_extra_info
      }
      gtr_expired_date
      is_gtr_active
      code_expired_date
      is_code_active
    }
  }
`;

export {
  CREATE_UPDATE_REQUEST,
  GET_UPDATE_REQUEST_LIST,
  GET_UPDATE_REQUEST,
  REVIEW_UPDATE_REQUEST,
};
